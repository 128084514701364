import moment from "moment";
import "moment/locale/es";
moment.locale("es");

const ordenarEventos = (eventos) => {
  const hoy = moment(new Date());
  const mesActual = hoy.month();

  const eventosOrdenados = [...eventos]
    .map((evento) => {
      // Filtrar fechas futuras o actuales
      const fechasFiltradas = evento.Fechas.filter((f) => {
        if (f.Fecha) {
          return moment(f.Fecha).isSameOrAfter(hoy, "day");
        } else {
          // Para rangos de fechas
          return moment(f.Fin).isSameOrAfter(hoy, "day");
        }
      }).map(f => {
        // Flags para clasificación de eventos
        const esRango = !f.Fecha;
        const estaActivo = esRango && moment(f.Inicio).isBefore(hoy, "day") && moment(f.Fin).isSameOrAfter(hoy, "day");

        // Normalizar fechas
        if (esRango) {
          const fechaInicio = estaActivo ? hoy.format("YYYY-MM-DD") : f.Inicio;
          return {
            ...f,
            InicioNormalizado: fechaInicio,
            esRango,
            estaActivo,
            diasParaFinalizar: moment(f.Fin).diff(hoy, "days") // Siempre usar hoy como referencia para eventos activos
          };
        } else {
          return {
            ...f,
            InicioNormalizado: f.Fecha,
            esRango,
            estaActivo: false,
            diasParaFinalizar: 0
          };
        }
      }).sort((a, b) => {
        // Ordenar las fechas del evento
        const fechaA = moment(a.InicioNormalizado);
        const fechaB = moment(b.InicioNormalizado);

        return fechaA.diff(fechaB, "days");
      });

      // Obtener la primera fecha válida después de ordenar
      const primeraFecha = fechasFiltradas.length > 0 ? fechasFiltradas[0] : null;

      return {
        evento: evento,
        fechas: fechasFiltradas,
        primeraFecha: primeraFecha,
        enMesActual: primeraFecha ? moment(primeraFecha.InicioNormalizado).month() === mesActual : false,
        iniciaHoy: primeraFecha ? moment(primeraFecha.InicioNormalizado).isSame(hoy, "day") : false
      };
    })
    .filter(item => item.fechas.length > 0) // Eliminar eventos sin fechas válidas
    .sort((a, b) => {
      // Aplicar las reglas de ordenación
      if (!a.primeraFecha || !b.primeraFecha) return 0;

      const fechaA = moment(a.primeraFecha.InicioNormalizado);
      const fechaB = moment(b.primeraFecha.InicioNormalizado);

      // 1. Priorizar eventos del mes actual
      if (a.enMesActual && !b.enMesActual) return -1;
      if (!a.enMesActual && b.enMesActual) return 1;

      // 2. Si ambos están en el mismo mes
      if (fechaA.month() === fechaB.month()) {
        // Primero comparar por día del mes
        const diaA = fechaA.date();
        const diaB = fechaB.date();

        if (diaA !== diaB) {
          // Si los días son diferentes, ordenar por día
          return diaA - diaB;
        } else {
          // Si los días son iguales

          // Primero, priorizar eventos de fecha única
          if (!a.primeraFecha.esRango && b.primeraFecha.esRango) return -1;
          if (a.primeraFecha.esRango && !b.primeraFecha.esRango) return 1;

          // Si ambos son eventos de rango
          if (a.primeraFecha.esRango && b.primeraFecha.esRango) {
            // Si ambos son eventos activos
            if (a.primeraFecha.estaActivo && b.primeraFecha.estaActivo) {
              // Orden inverso: primero los que terminan más tarde
              // Esto coloca al final de la lista los que están próximos a finalizar
              return b.primeraFecha.diasParaFinalizar - a.primeraFecha.diasParaFinalizar;
            }

            // Priorizar eventos que comienzan hoy realmente vs los activos
            if (!a.primeraFecha.estaActivo && b.primeraFecha.estaActivo) return -1;
            if (a.primeraFecha.estaActivo && !b.primeraFecha.estaActivo) return 1;
          }

          return 0;
        }
      }

      // 3. Si están en distintos meses, ordenar por mes ascendente
      return fechaA.month() - fechaB.month();
    });

  // Devolver solo los eventos y sus fechas filtradas
  return eventosOrdenados.map(item => ({
    evento: item.evento,
    fechas: item.fechas
  }));
};

export default ordenarEventos;


// import moment from "moment";
// import "moment/locale/es";
// moment.locale("es");

// const ordenarEventos = (eventos) => {
//   const hoy = moment(new Date());

//   return [...eventos]
//     .map((evento) => ({
//       evento,
//       fechas: evento.Fechas.filter((f) =>
//         f.Fecha
//           ? moment(f.Fecha).isSameOrAfter(hoy, "day")
//           : hoy.isBetween(moment(f.Inicio), moment(f.Fin), "day", "[]") ||
//             moment(f.Inicio).isSameOrAfter(hoy, "day")
//       ).sort((a, b) => {
//         const duracionA = a.Fecha
//           ? moment.duration(moment(a.Fecha).diff(hoy))
//           : moment.duration(moment(a.Fin).diff(moment(a.Inicio)));
//         const duracionB = b.Fecha
//           ? moment.duration(moment(b.Fecha).diff(hoy))
//           : moment.duration(moment(b.Fin).diff(moment(b.Inicio)));

//         return duracionA.asMilliseconds() - duracionB.asMilliseconds();
//       }),
//     }))
//     .sort((a, b) => {
//       if (!a.fechas.length || !b.fechas.length) return 0;

//       const [af] = a.fechas;
//       const [bf] = b.fechas;

//       if (af.Fecha && moment(af.Fecha).isSame(hoy, "day")) return -1;
//       if (bf.Fecha && moment(bf.Fecha).isSame(hoy, "day")) return 1;

//       if (hoy.isBetween(moment(af.Inicio), moment(af.Fin), "day", "[]"))
//         return -1;
//       if (hoy.isBetween(moment(bf.Inicio), moment(bf.Fin), "day", "[]"))
//         return 1;

//       const duracionA = af.Fecha
//         ? moment.duration(moment(af.Fecha).diff(hoy))
//         : moment.duration(moment(af.Fin).diff(moment(af.Inicio)));
//       const duracionB = bf.Fecha
//         ? moment.duration(moment(bf.Fecha).diff(hoy))
//         : moment.duration(moment(bf.Fin).diff(moment(bf.Inicio)));

//       return duracionA.asMilliseconds() - duracionB.asMilliseconds();
//     });
// };

// export default ordenarEventos;
